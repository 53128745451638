import Vue from 'vue'
import VueNoty from 'vuejs-noty'

import VueRouter from 'vue-router'

import Login from '../components/Auth/Login.vue'

import PageNotFound from '../views/NotFound.vue'

Vue.use(VueNoty)

import 'vuejs-noty/dist/vuejs-noty.css'
Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router
