import axios from 'axios';
import swal from 'sweetalert';
import { isEmpty } from 'lodash';
import { setHttpToken } from '../../../helpers/index';
import { apiGet } from '../../../services/api';

export const login = ({ dispatch }, { payload }) => {
  return axios
    .post(process.env.VUE_APP_ROOT_API + 'login', payload)
    .then((result) => {
      if (result.data.success == false) {
        swal('Error!', result.data.message, 'error');
      } else {
        dispatch('setToken', result.data.data.token).then(() => {
          dispatch('fetchUser', result.data.data);
          // router.push({ name: 'Dashboard' })
        });
        // this.$router.push({ name: "dashboard"})
        window.location.replace('/');
      }
    })
    .catch((err) => {
      swal('Error!', err.message, 'error');
      // console.log(err.message);
    });
};

export const setToken = ({ commit, dispatch }, token) => {
  if (isEmpty(token)) {
    return dispatch('checkTokenExist').then((token) => {
      setHttpToken(token);
    });
  }
  commit('setToken', token);
  setHttpToken(token);
};

export const removeToken = ({ commit }) => {
  commit('setAuthenticated', false);
  commit('setUserData', null);
  commit('setToken', null);
  commit('setMenuData', null);
  setHttpToken(null);
};

export const fetchUser = ({ commit }) => {
  return axios
    .get(process.env.VUE_APP_ROOT_API + 'get_user')
    .then((result) => {
      if (result.data.code == 401) {
        this.$router.push({ name: 'Login' });
        localStorage.removeItem('token');
      }

      commit('setAuthenticated', true);
      commit('setUserData', result.data.data);
      let user_id = result.data.data.role.id;
      apiGet('role/' + user_id).then((res) => {
        commit(
          'setMenuData',
          res.data.data.menu_child.filter((e) => e.status == true)
        );
      });
    })
    .catch((err) => {
      console.log(err.response?.data);
    });
};

export const checkTokenExist = () => {
  const token = localStorage.getItem('token');

  if (isEmpty(token)) {
    return Promise.reject('NO_STORAGE_FOUND');
  }
  return Promise.resolve(token);
};
