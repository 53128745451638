import {apiGet} from '../../../services/api'
import store from '../../../store/index'
import router from '../../../router/index'

export const dispatchGetMenus = async({commit, state}) => {
    return await apiGet('role/'+store.state?.auth?.user?.data?.role?.id).then(res => {
        state.menus = res.data.data.menu_child.filter(e => e.status == true)
    }).catch(() => {
        store.dispatch('auth/fetchUser').then(() => {
            store.dispatch('auth/removeToken').then(() => {
                router.replace('/login')
            })
        })
    })
}