<template>
  <nav class="navbar navbar-expand-lg main-navbar">
    <form class="form-inline mr-auto">
      <ul class="navbar-nav mr-3">
        <li>
          <a
            id="toggle_sidebar"
            href="#"
            data-toggle="sidebar"
            class="nav-link nav-link-lg"
            ><i class="fas fa-bars"></i
          ></a>
        </li>
        <li>
          <a
            href="#"
            data-toggle="search"
            class="nav-link nav-link-lg d-sm-none"
            ><i class="fas fa-search"></i
          ></a>
        </li>
      </ul>
    </form>
    <ul class="navbar-nav navbar-right">
      <li class="dropdown dropdown-list-toggle">
        <!-- <a
          href="#"
          data-toggle="dropdown"
          class="nav-link notification-toggle nav-link-lg beep"
          ><i class="far fa-bell"></i
        ></a> -->
        <a
          href="#"
          data-toggle="dropdown"
          class="nav-link notification-toggle nav-link-lg"
          ><i class="far fa-bell"></i
        ></a>
        <div class="dropdown-menu dropdown-list dropdown-menu-right">
          <div class="dropdown-header">Notifications</div>
          <div
            class="dropdown-list-content dropdown-list-icons"
            style="height: 100%"
          >
            <a
              class="dropdown-item dropdown-item-unread"
              v-for="(notification, index) in notifications"
              :key="index"
            >
              <div class="dropdown-item-desc">
                <div class="time text-primary">
                  {{ notification.title }}
                </div>
                <p>
                  {{ notification.message }}
                </p>
                <div class="time text-primary">
                  {{ notification.created_at_diff }}
                </div>
              </div>
            </a>
          </div>
          <!-- <div class="dropdown-footer text-center">
            <a href="#">View All <i class="fas fa-chevron-right"></i></a>
          </div> -->
        </div>
      </li>
      <li class="dropdown">
        <a
          href="#"
          data-toggle="dropdown"
          class="nav-link dropdown-toggle nav-link-lg nav-link-user"
        >
          <img
            alt="image"
            src="../../../public/img/avatar/avatar-1.png"
            class="rounded-circle mr-1"
          />
          <div class="d-sm-none d-lg-inline-block" v-if="user.data">
            Hi, {{ user.data && user.data.username }}
          </div></a
        >
        <div class="dropdown-menu dropdown-menu-right">
          <router-link :to="{ name: 'Profile' }" class="dropdown-item has-icon">
            <i class="far fa-user"></i> Profile
          </router-link>
          <div class="dropdown-divider"></div>
          <button
            @click="logout"
            class="dropdown-item has-icon d-flex align-items-center text-danger"
          >
            <i class="fas fa-sign-out-alt"></i> Logout
          </button>
        </div>
      </li>
    </ul>
  </nav>
</template>
<script>
import { mapGetters } from 'vuex';
import { apiPost } from '../../services/api';

export default {
  props: ['notifications'],
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  methods: {
    toggleNavbar() {
      console.log('toggle nav');
    },
    logout() {
      apiPost('logout')
        .then(() => {
          this.$router.push({ name: 'Login' });
          this.$noty.success('Your logged out succesfully!');
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
