import Vue from 'vue'
import Vuex from 'vuex'
import barcode from '../components/Auth/barcode'
import auth from "../components/Auth/store"
import menu from "../components/menus/store"

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        auth: auth,
        barcode: barcode,
        menu: menu
    },
    state() {
        return {
            token: "Bearer " + localStorage.getItem("token"),
            api: process.env.VUE_APP_ROOT_API
        }
    }
})
export default store;
