<template>
  <section
    class="section"
    style="height: 100.8vh;background: url(https://liquidcorporate.xyz/storage/img-web-liquid-1.png)"
  >
    <div class="container pt-5 h-100">
      <div class="row w-100 justify-content-center">
        <div class="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
          <div class="card card-primary p-2">
            <div
              class="card-header text-center justify-center justify-content-center"
            >
              <img
                src="https://liquidcorporate.xyz/storage/logo-liquid-priemiere.png"
                style="height: 50px"
                alt=""
              />
            </div>

            <div class="card-body">
              <div class="text-right">
                <button
                  class="btn btn-primary text-right"
                  @click="toggleMethod"
                  v-if="!useBarcode"
                  :disabled="isSubmitting"
                >
                  Barcode
                </button>
                <button
                  class="btn btn-primary text-right"
                  @click="toggleMethod"
                  :disabled="isSubmitting"
                  v-else
                >
                  Username Password
                </button>
              </div>
              <div v-if="!useBarcode">
                <form
                  class="needs-validation"
                  novalidate=""
                  @submit.prevent="submitForm"
                >
                  <div class="form-group">
                    <label for="email">Email</label>
                    <input
                      id="email"
                      type="email"
                      class="form-control"
                      name="email"
                      tabindex="1"
                      required
                      autofocus
                      v-model="form.email"
                    />
                    <div class="invalid-feedback">
                      Please fill in your email
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="d-block">
                      <label for="password" class="control-label"
                        >Password</label
                      >
                    </div>
                    <input
                      id="password"
                      type="password"
                      class="form-control"
                      name="password"
                      tabindex="2"
                      v-model="form.password"
                      required
                    />
                    <div class="invalid-feedback">
                      please fill in your password
                    </div>
                  </div>
                  <small class="text-danger">
                    {{ errors }}
                  </small>
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input
                        v-model="isRemember"
                        type="checkbox"
                        name="remember"
                        class="custom-control-input"
                        tabindex="3"
                        id="remember-me"
                      />
                      <label class="custom-control-label" for="remember-me"
                        >Remember Me</label
                      >
                    </div>
                  </div>

                  <div class="form-group">
                    <button
                      type="submit"
                      class="btn btn-primary btn-lg btn-block"
                      tabindex="4"
                      :disabled="isSubmitting"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div v-else class="mt-2 text-center">
                <StreamBarcodeReader
                  @decode="onDecode"
                  @loaded="onLoaded"
                ></StreamBarcodeReader>
                <p v-if="isSubmitting">Please Wait ...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p
        style="position: absolute; bottom: 10px; left: 0"
        class="w-100 text-center text-white"
      >
        Copyright &copy; CAN Creative {{ new Date().getFullYear() }}
      </p>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
import { StreamBarcodeReader } from "vue-barcode-reader";
export default {
  name: "Login",
  components: { StreamBarcodeReader },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      errors: null,
      isSubmitting: false,
      isRemember: false,
      useBarcode: false,
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      barcode: "barcode/login",
    }),
    toggleMethod() {
      this.useBarcode = !this.useBarcode;
    },
    onDecode(result) {
      let isAuthenticated = localStorage.getItem("token") != null;
      this.isSubmitting = true;
      this.barcode({
        payload: {
          aplication_user_id: result,
        },
        context: this,
      })
        .then(() => {
          if (isAuthenticated) {
            this.$router.push({ name: "Dashboard" });
          } else {
            this.$router.push({ name: "Login" });
            this.isSubmitting = false;
          }
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
    submitForm() {
      let isAuthenticated = localStorage.getItem("token") != null;
      this.isSubmitting = true;
      this.login({
        payload: {
          email: this.form.email,
          password: this.form.password,
        },
        context: this,
      })
        .then(() => {
          if (isAuthenticated) {
            this.$router.push({ name: "Dashboard" });
          } else {
            this.$router.push({ name: "Login" });
            this.isSubmitting = false;
          }
        })
        .catch(() => {
          this.isSubmitting = false;
        });
    },
  },
};
</script>
