import axios from "axios";
import swal from "sweetalert";
import { isEmpty } from "lodash";
import { setHttpToken } from "../../../helpers/index"


export const login = ({ dispatch }, { payload }) => {
    return axios
        .post(process.env.VUE_APP_ROOT_API + "login_number_member", payload)
        .then((result) => {
            if (result.data.success == false) {
                swal("Error!", result.data.message, "error");
            } else {
                dispatch("setToken", (result.data.data.token)).then(() => {
                    dispatch('fetchUser', (result.data.data))
                })
                window.location.replace("/dashboard");
            }
        }).catch((err) => {
            swal("Error!", err.message, "error");
            // console.log(err.message);
        })
};

export const setToken = ({ commit, dispatch }, token) => {
    if (isEmpty(token)) {
        return dispatch("checkTokenExist")
            .then((token) => {
                setHttpToken(token)
            })
    }
    commit("setToken", token);
    setHttpToken(token);
}

export const removeToken = ({ commit }) => {
    commit("setAuthenticated", false);
    commit("setUserData", null);
    commit("setToken", null)
    setHttpToken(null);
}

export const fetchUser = ({ commit }) => {
    return axios
        .get(process.env.VUE_APP_ROOT_API + "get_user")
        .then((result) => {
            commit("setAuthenticated", true);
            commit("setUserData", result.data.data)
        }).catch((err) => {
            console.log(err.response.data)
        });
}

export const checkTokenExist = () => {
    const token = localStorage.getItem('token');

    if (isEmpty(token)) {
        return Promise.reject("NO_STORAGE_FOUND")
    }
    return Promise.resolve(token);
}
