import axios from "axios"
import store from "../store"

const apiPost = (url, data, headers) => {
    if(!url.startsWith('http')) {
        url = process.env.VUE_APP_ROOT_API + url
    }
    headers = Object.assign({
        'Authorization': store.state.token
    }, headers);

    return axios.post(url, data, { 
        data: data,
        headers: headers
    })
}

const apiGet = (url, data, headers) => {
    if(!url.startsWith('http')) {
        url = process.env.VUE_APP_ROOT_API + url
    }

    return axios.get(url, {
        params: data,
        headers: Object.assign({
            'Authorization': store.state.token
        }, headers)
    })
}

const apiDelete = (url, data, headers) => {
    if(!url.startsWith('http')) {
        url = process.env.VUE_APP_ROOT_API + url
    }

    headers = Object.assign({
        'Authorization': store.state.token
    }, headers);

    return axios.delete(url, data, { 
        data: data,
        headers: headers
    })
}

const apiDownload = (url, filename, data, headers) => {
    if(!url.startsWith('http')) {
        url = process.env.VUE_APP_ROOT_API + url
    }

    headers = Object.assign({
        'Authorization': store.state.token
    }, headers);

    return fetch(url, {
        method: "GET",
        headers: headers
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          a.remove();
          return blob
        })
        .catch((error) => {
          this.errors = error.response.data;
        });
}

export { apiPost, apiGet, apiDelete, apiDownload }