import firebase from "firebase/app"
import "firebase/firebase-messaging"

var firebaseConfig = {
    apiKey: "AIzaSyA-wnQtV2wN_jaLvMveVQOolnQl39CTkJ8",
    authDomain: "liquid-coorporate.firebaseapp.com",
    databaseURL: "https://liquid-coorporate-default-rtdb.firebaseio.com",
    projectId: "liquid-coorporate",
    storageBucket: "liquid-coorporate.appspot.com",
    messagingSenderId: "181331319320",
    appId: "1:181331319320:web:28427d3b55078eb522816d",
    measurementId: "G-5G39H4SNL5",
};

firebase.initializeApp(firebaseConfig)

export default firebase.messaging()
