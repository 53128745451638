import { isEmpty } from 'lodash'
export const setToken = (state, token) => {
    if (isEmpty(token)) {
        localStorage.removeItem('token');
        return
    }
    console.log(state);
    localStorage.setItem('token', token)
}

export const setAuthenticated = (state, trueOrFalse) => {
    state.user.authenticated = trueOrFalse;
}

export const setUserData = (state, data) => {
    state.user.data = data;
}