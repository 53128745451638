import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import vuetify from './plugins/vuetify';
import BaseHeader from './components/UI/Table/BaseHeader.vue';
import BaseTitle from './components/UI/Form/BaseTitle.vue';
import store from './store/index';
import Pagination from 'vue-pagination-2';
import axios from 'axios';
import { ModelListSelect } from 'vue-search-select';
import vSelect from 'vue-select';
import VueImg from 'v-img';
import money from 'v-money';
import DateRangePicker from 'vue2-daterange-picker';

import 'vue-select/dist/vue-select.css';
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

Vue.config.productionTip = false;
Vue.component('date-range-picker', DateRangePicker);
Vue.component('v2-select', vSelect);
Vue.component('model-list-select', ModelListSelect);
Vue.component('base-header', BaseHeader);
Vue.component('base-title', BaseTitle);
Vue.component('pagination', Pagination);
Vue.use(VueImg);
Vue.use(money, { precision: 0 });

Vue.component('filter-table', () =>
  import('./components/tables/FilterTable.vue')
);

Vue.component('text-input', () => import('./components/forms/TextInput.vue'));
Vue.component('select-input', () =>
  import('./components/forms/SelectInput.vue')
);
Vue.component('textarea-input', () =>
  import('./components/forms/TextareaInput.vue')
);
Vue.component('file-input', () => import('./components/forms/FileInput.vue'));
Vue.component('filter-button', () =>
  import('./components/tables/FilterButton.vue')
);

store
  .dispatch('auth/setToken')
  .then(() => {
    store.dispatch('auth/fetchUser').then(() => {
      store.dispatch('dispatchGetMenus').then(() => {
        store.state.menu.menus.forEach((e) => {
          const parsePath = (path) => {
            if (path.charAt(0) != '/') {
              path = '/' + path;
            }
            return path;
          };
          let path = parsePath(e.path);
          router.addRoute({
            path: path,
            name: e.menu_name,
            component: () => import(`./views/${e.component}`),
          });
        });
        router.addRoute({
          path: '*',
          component: () => import('./views/NotFound.vue'),
        });
      });
    });
  })
  .catch(() => {
    store.dispatch('auth/removeToken');
  });

if (store.state.auth.user.authenticated == true) {
  router.beforeEach((to, __, next) => {
    let isAuthenticated = localStorage.getItem('token') != null;
    if (to.name !== 'Login' && isAuthenticated) {
      axios
        .get(process.env.VUE_APP_ROOT_API + 'get_user', {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        .then((res) => {
          if (res.data.success === false) {
            localStorage.removeItem('token');
            next({ name: 'Login' });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (to.name !== 'Login' && !isAuthenticated) {
      localStorage.removeItem('token');
      next({ name: 'Login' });
    } else {
      next();
    }
  });
}
new Vue({
  data: {
    api: process.env.VUE_APP_ROOT_API,
  },
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
