<template>
  <div
    class="main-sidebar sidebar-style-2"
    tabindex="1"
    style="overflow: hidden; outline: none; cursor: auto; touch-action: auto"
  >
    <aside id="sidebar-wrapper">
      <div class="sidebar-brand">
        <router-link :to="{ name: 'Dashboard' }">POS Liquid</router-link>
      </div>
      <div class="sidebar-brand sidebar-brand-sm">
        <router-link :to="{ name: 'Dashboard' }">POS</router-link>
      </div>
      <ul class="sidebar-menu">
        <li
          v-for="menu in menus?.filter((e) => e.is_index == true)"
          :key="menu.id"
        >
          <router-link :to="{ name: menu.menu_name }" class="nav-link">
            <i :class="menu.icon"></i> <span>{{ menu.sidebar_name }}</span>
          </router-link>
        </li>
      </ul>
    </aside>
  </div>
</template>
<script>
export default {
  name: 'Sidebar',
  props: ['menus'],
};
</script>
<style scoped>
.router-link-exact-active {
  color: #6777ef !important;
  font-weight: 600;
  background-color: #f8fafb;
}
</style>
