import { isEmpty } from 'lodash'

export const setToken = (state, token) => {
    if (isEmpty(token)) {
        localStorage.removeItem('token');
        return
    }
    // console.log(state);
    localStorage.setItem('token', token)
    state.user.token = token;
}

export const setAuthenticated = (state, trueOrFalse) => {
    state.user.authenticated = trueOrFalse;
}

export const setUserData = (state, data) => {
    state.user.data = data;
}
export const setMenuData = (state, data) => {
    state.user.menus = data;
}