<template>
  <!-- <v-app> -->
  <div id="app">
    <div class="main-wrapper" v-if="$route.path != '/login'">
      <div class="navbar-bg"></div>
      <the-navbar :notifications="notifications"></the-navbar>
      <the-sidebar :menus="user.menus"></the-sidebar>
      <div class="main-content" style="min-height: 229px">
        <router-view></router-view>
      </div>
      <the-footer></the-footer>
    </div>
    <router-view v-else></router-view>
  </div>
</template>

<script>
import TheNavbar from './components/layouts/TheNavbar.vue';
import TheSidebar from './components/layouts/TheSidebar.vue';
import TheFooter from './components/layouts/TheFooter.vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import firebaseMessaging from '../public/firebase';
import { apiGet } from './services/api';

export default {
  data() {
    return {
      notifications: [],
      // menus: this.$store.state.menu,
    };
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
  },
  mounted() {
    // const body = document.body;
    // body.classList.add('sidebar-mini');
    firebaseMessaging.onMessage((payload) => {
      this.$noty.success(payload.notification.title);
    });
    if (localStorage.getItem('token') == null) {
      this.$router.push({ name: 'Login' });
    }
  },
  watch: {
    $route(to, from) {
      const messaging = firebaseMessaging;
      messaging
        .getToken({
          vapid:
            'BHSBSgJxtu0fB3bX8vM81IsB7AfphKQuM0VSQ1qAbcVP4ORM5cq3zMs7R4tu371eqx6ho7iYQSnZOgteIdf_MPY',
        })
        .then((token) => {
          let formData = new FormData();
          formData.set('fcm_token', token);
          axios.post(process.env.VUE_APP_ROOT_API + 'fcm_token', formData, {
            headers: {
              Authorization: this.$store.state.token,
            },
          });
        });
      apiGet('notification').then((result) => {
        this.notifications = result.data.data;
      });
      apiGet('get_user').then((res) => {
        if (res.data.code == 401) {
          this.$router.push({ name: 'Login' });
          localStorage.removeItem('token');
        }
      });
      // console.log(this.$store.state.auth.user.authenticated);
    },
  },
  name: 'App',
  components: {
    TheNavbar,
    TheSidebar,
    TheFooter,
  },
};
</script>
<style>
.daterangepicker.openscenter {
  left: 360% !important;
}
.route-enter-active {
  animation: slide-scale 0.4s ease-out;
}
.route-leave-active {
  animation: slide-scale 0.4s ease-in;
}
@keyframes slide-scale {
  0% {
    transform: translateX(0) scale(1);
  }
  70% {
    transform: translateX(-120px) scale(1.1);
  }
  100% {
    transform: translateX(-150px) scale(1);
  }
}
.theme--light.v-pagination .v-pagination__item--active {
  background-color: #6777ef !important;
  border-color: #6777ef !important;
  color: #fff !important;
}
.v-pagination__navigation .v-icon,
.v-pagination__item {
  color: #6777ef !important;
}
.v-pagination__item:hover {
  background-color: #6777ef !important;
  border-color: #6777ef !important;
  color: #fff !important;
}
.btn-primary {
  color: #fff !important;
}
button:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
}
.btn-danger,
.btn-danger.disabled {
  color: white !important;
}
</style>
